import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import uuid from "uuid/v4";

import { Form, Result } from "antd";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";

import Button from "components/shared/Button";
import LeasingForm from "components/forms/leasingForm";
import GuarantorForm from "components/forms/guarantorForm";
import BankSelector from "components/nordigen/BankSelector";
import DokobitModal from "components/forms/DokobitModal";

import {
  saveToLocalStorage,
  getFromLocalStorage,
  cleanLocalStorage,
  postBankSelection,
  validateFinancingRequest,
  validatePrefillFinancingRequest,
  postFinancingRequest,
  postPrefillFinancingRequest,
  validatePartnerTokens,
  validatePartnerPrivateToken,
  APPLICANT_ROLE,
  validateApplicantData,
  postApplicantData,
  getDokobitSingingStatus,
  FINANCING_COMPANY,
  storeFinancingCompany,
} from "services/formService.js";
import { getLegalTexts } from "services/legalService";
import { stripStringToCurrency, stripStringToInteger } from "utils/formUtils";
import { errorNotification } from "utils/notificationUtils";
import { getFormSuccessStateMessage } from "utils/translationUtils";
import PartnerLogo from "components/forms/PartnerLogo";
import ApplicantAccessOverlay from "components/forms/ApplicantAccessOverlay";
import LoadingSpin from "components/shared/LoadingSpin";
import SupportInfoBlock from "components/forms/SupportInfoBlock";

const LeasingRequest = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const language = i18next.language;
  const location = useLocation();
  const params = queryString.parse(location.search);
  const { applicationToken, originatorToken, originatorPrivateToken } = useParams();

  const [form] = Form.useForm();
  const [formDisplayMode, setFormDisplayMode] = useState({
    loading: true, // display form initial setup
    error: false, // form final processing error
    success: false, // form final processing success
  });
  const [formReadOnly, setFormReadOnly] = useState(false);
  const [formAsPartner, setFormAsPartner] = useState(false);
  const [formAsGuarantor, setFormAsGuarantor] = useState(false);
  const [formLoading, setFormLoading] = useState(false); // form submission block (pending process)

  const [initialValues, setInitialValues] = useState(null);

  const [legalData, setLegalData] = useState({
    propertyValue: null,
    propertyInsurance: null,
    confirmation: null,
  });
  const [validatingAccess, setValidatingAccess] = useState(false);
  const [bankModalOpen, setBankModalOpen] = useState(false);
  const [dokobitModalOpen, setDokobitModalOpen] = useState(false);
  const [dokobitFrameUrl, setDokobitFrameUrl] = useState(null);
  const [prefillOriginatorToken, setPrefillOriginatorToken] = useState();

  const isPrefillForm = !!originatorPrivateToken;

  useEffect(() => {
    const storedData = getFromLocalStorage();
    storeFinancingCompany(FINANCING_COMPANY.G4R);

    const defaultValues = {
      lending: {
        type: "LEASING",
        prefill: isPrefillForm,
        partner_quote: true,
        property_value: stripStringToCurrency(params.vehiclePrice),
        period: stripStringToInteger(params.period),
        monthly_payment: stripStringToCurrency(params.monthPay),
        is_insurance_selected: false,
        is_insurance_kasko: true,
        is_insurance_ca: true,
        is_registration_fee_included: false,
        pipedrive_id: params.ID,
        originator_token: originatorToken,
      },
      applicant: {
        type: "PRIVATE",
        phone: params.phone?.replace(" ", "+"),
        email: params.email,
        is_marketing_consent: params.marketingAgreement === "true",
      },
      co_applicant: {
        type: "PRIVATE",
      },
      meta: {
        is_guarantor_selected: false,
      },
    };

    if (applicationToken && params.token) {
      // URL access, wait for AccessOverlay
      setFormDisplayMode({
        ...formDisplayMode,
        loading: false,
      });
      setValidatingAccess(true);
    } else if (originatorToken) {
      (async () => {
        // validate tokens before proceeding
        const response = await validatePartnerTokens(originatorToken, params.token);
        if (!response.ok) {
          history.push("/404");
        }
        setFormAsPartner(true);
        setInitialValues({
          ...defaultValues,
        });
      })();
    } else if (originatorPrivateToken) {
      (async () => {
        const response = await validatePartnerPrivateToken(originatorPrivateToken);
        if (!response.ok) {
          history.push("/404");
        }
        setFormAsPartner(true);
        defaultValues.lending.originator_token = response.payload?.originator_token;
        setInitialValues({
          ...defaultValues,
        });
        setPrefillOriginatorToken(response.payload?.originator_token);
      })();
    } else if (params.ref) {
      // redirected back from bank authorisation
      if (storedData && storedData?.signing?.md5_code === params.ref) {
        if (params.error) {
          if (params.error?.toLowerCase().startsWith("ssn")){
            errorNotification(t("error"), t("g4rFormErrorWrongSsn"));
            redirectBack();
            return;
          }

          setFormDisplayMode({ ...formDisplayMode, error: true });
        } else {
          processForm(storedData);
        }
        return;
      } else {
        // refresh with nordigen ref token, but already submitted
        history.push("/404");
      }
    } else if (storedData) {
      if (params.error) {
        setFormDisplayMode({ ...formDisplayMode, error: true });
        return;
      }

      if (storedData.lending?.originator_token) {
        setFormAsPartner(true);
      }
      // redirected back, restore local storage
      setInitialValues(storedData);
      cleanLocalStorage();
    } else {
      // redirected here, fetch values from URL
      setInitialValues({
        ...defaultValues,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (null !== initialValues) {
      setFormDisplayMode({ ...formDisplayMode, loading: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    getLegalData(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (formAsPartner && (originatorToken || originatorPrivateToken)) {
      setInitialValues((initialValues) => {
        initialValues.lending.is_property_selected = false;
        return initialValues;
      });
    }
  }, [formAsPartner, originatorToken, originatorPrivateToken]);

  const getLegalData = async (lang) => {
    const response = await getLegalTexts(lang);

    if (!response) return;

    const propertyValue = response.find((item) => item.title === "G4rForm Info Property value");
    const propertyInsurance = response.find((item) => item.title === "G4rForm Info Property Insurance");
    const confirmation = response.find((item) => item.title === "G4rForm Confirmation");
    const marketing = response.find((item) => item.title === "G4rForm Marketing Consent");
    const marketingTooltip = response.find((item) => item.title === "G4rForm Marketing Consent Detailed");

    setLegalData({
      propertyValue,
      propertyInsurance,
      confirmation,
      marketing,
      marketingTooltip,
    });
  };

  const processForm = async (storedData) => {
    const response = await postFinancingRequest(storedData);

    if (!response.ok) {
      if (response.payload?.code === "SME78"){
        errorNotification(t("error"), t("g4rFormErrorWrongFullname"));
        redirectBack();
        return;
      }

      setFormDisplayMode({ ...formDisplayMode, error: true });
      return;
    }

    cleanLocalStorage();
    setFormDisplayMode({ ...formDisplayMode, success: true });
  };

  const onFinish = async () => {
    setFormLoading(true);

    if (formAsGuarantor) {
      const appToken = applicationToken ?? initialValues?.meta?.application_token;
      const applicantToken = params.token ?? initialValues?.meta?.applicant_token;
      const validationResponse = await validateApplicantData(appToken, applicantToken, form.getFieldsValue());

      if (!validationResponse.ok) {
        setFormLoading(false);
        errorNotification(t("error"), t("g4rFormValidateFieldError"), 5);
        return;
      }
      const postResponse = await postApplicantData(appToken, applicantToken, {
        ...form.getFieldsValue(),
        signing: {
          type: "DOKOBIT",
        },
      });
      if (postResponse.ok && postResponse.payload.follow) {
        setDokobitModalOpen(true);
        setDokobitFrameUrl(postResponse.payload.follow);
      } else {
        setFormLoading(false);
        errorNotification(t("error"), t("leasingFormDokobitConfirmationError"), 5);
      }
    } else {
      isPrefillForm
        ? validateAndSubmitPrefillFormData(form.getFieldsValue())
        : validateFormDataAndProceed(form.getFieldsValue());
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'application_success',
      'product': 'leasing_go4rent'
    });
  };

  const validateAndSubmitPrefillFormData = async (values) => {
    const validationResponse = await validatePrefillFinancingRequest(values);
    if (!validationResponse.ok) {
      setFormLoading(false);
      errorNotification(t("error"), t("g4rFormValidateFieldError"), 5);
      return;
    }

    const response = await postPrefillFinancingRequest(values);
    if (!response.ok) {
      setFormDisplayMode({ ...formDisplayMode, error: true });
      return;
    }

    cleanLocalStorage();
    setFormDisplayMode({ ...formDisplayMode, success: true });
  };

  const validateFormDataAndProceed = async (values) => {
    const validationResponse = await validateFinancingRequest(values);
    if (!validationResponse.ok) {
      setFormLoading(false);
      errorNotification(t("error"), t("g4rFormValidateFieldError"), 5);
      return;
    }
    setBankModalOpen(true);
  };

  const onBankSelect = async (bankId) => {
    setBankModalOpen(false);
    if (!bankId) {
      setFormLoading(false);
      return; // closed/cancelled
    }
    const formValues = form.getFieldsValue();
    const md5Code = uuid().replace(/-/g,"");

    const response = await postBankSelection(bankId, md5Code, language.toUpperCase(), formValues.applicant?.code);
    if (response.error) {
      errorNotification(t("error"), t("g4rFormErrorBank"), 5);
      return;
    }

    saveToLocalStorage({
      ...formValues,
      signing: {
        type: "NORDIGEN",
        md5_code: md5Code,
        requisition_id: response.payload.requisition_id,
      },
    });

    window.location.href = response.payload.url;
  };

  const onDokobitFrameReload = async () => {
    if (dokobitFrameUrl) {
      try {
        const response = await getDokobitSingingStatus(dokobitFrameUrl.split("/").pop().split("?").shift());
        if (response.ok && response.payload === "completed") {
          setFormDisplayMode({ ...formDisplayMode, success: true });
          return;
        }
      } catch (e) {
        console.error(e);
        // malformed URL for token retrieval?
        setFormDisplayMode({ ...formDisplayMode, error: true });
      }
    }
  };

  const onDokobitFrameClose = () => {
    setDokobitFrameUrl(null);
    setFormLoading(false);
  };

  const redirectBack = () => {
    setFormDisplayMode({
      ...formDisplayMode,
      submission: false,
      error: false,
      render: true,
    });
    setFormLoading(false);
    history.push(window.location.pathname);
  };

  const redirectHome = () => {
    isPrefillForm ? (window.location.href = window.location.pathname) : (window.location.href = "https://go4rent.lt/");
  };

  const getFormHeader = () => {
    if (formDisplayMode.error || formDisplayMode.success || formDisplayMode.loading) {
      return <>&nbsp;</>;
    } else if (formAsPartner) {
      return (
        <>
          <PartnerLogo partnerToken={originatorToken || prefillOriginatorToken} />
          <div className="public-form__info-box">
            {t("intro")}<br />
            <b>{t("drive")}</b> {t("alreadyToday")}
          </div>
        </>
      );
    } else if (formReadOnly || formAsGuarantor) {
      // order matters
      return <>&nbsp;</>;
    } else {
      return <h1 className="public-form__heading">{t("leasingFormTitle")}</h1>;
    }
  };

  const dataLoaded = (data) => {
    data.applicant.token = params.token;
    setInitialValues({
      ...data,
      meta: {
        application_token: applicationToken,
        applicant_token: params.token,
        is_guarantor_selected: !!data.co_applicant,
      },
      co_applicant: {
        ...data.co_applicant,
        type: "PRIVATE",
      },
    });
    setFormReadOnly(APPLICANT_ROLE.isPrimary(data?.applicant?.role) && !data?.lending?.prefill);
    setFormAsPartner(!!data.is_partner);
    setFormAsGuarantor(APPLICANT_ROLE.isGuarantor(data.applicant.role));
    setValidatingAccess(false);
  };

  return (
    <>
      {validatingAccess ? (
        <ApplicantAccessOverlay
          applicationToken={applicationToken}
          applicantToken={params.token}
          onSuccess={dataLoaded}
          onFailure={() => history.push("/404")}
        />
      ) : (
        <div className="public-form public-form--go4rent-theme">
          <div className="public-form__header">
            {getFormHeader()}
            <SupportInfoBlock />
          </div>
          <div className="public-form__form-block block">
            {(formDisplayMode.error && (
              <Result
                icon={<FrownOutlined className="public-form__state-icon" />}
                title={t(isPrefillForm ? "prefillFormErrorState" : "g4rFormErrorState")}
                extra={
                  <Button type="primary" size="large" onClick={redirectBack}>
                    {t(isPrefillForm ? "prefillFormErrorStateButton" : "g4rFormErrorStateButton")}
                  </Button>
                }
              />
            )) ||
              (formDisplayMode.success && (
                <Result
                  icon={<SmileOutlined className="public-form__state-icon" />}
                  title={t(
                    isPrefillForm
                      ? "prefillFormSuccessState"
                      : getFormSuccessStateMessage(formAsGuarantor ? APPLICANT_ROLE.GUARANTOR : APPLICANT_ROLE.PRIMARY)
                  )}
                  extra={
                    <Button type="primary" size="large" onClick={redirectHome}>
                      {t(isPrefillForm ? "prefillFormSuccessStateButton" : "g4rFormSuccessStateButton")}
                    </Button>
                  }
                />
              )) ||
              (formDisplayMode.loading && <LoadingSpin />) || (
                <>
                  {(formAsGuarantor && (
                    <GuarantorForm
                      formInstance={form}
                      onFormFinish={onFinish}
                      initialValues={initialValues}
                      loading={formLoading}
                      legalData={legalData}
                    />
                  )) || (
                    <LeasingForm
                      formInstance={form}
                      onFormFinish={onFinish}
                      initialValues={initialValues}
                      loading={formLoading}
                      legalData={legalData}
                      readOnly={formReadOnly}
                      isPartner={formAsPartner}
                      prefill={isPrefillForm}
                    />
                  )}
                </>
              )}
          </div>
          {!formReadOnly && bankModalOpen && <BankSelector onFinish={onBankSelect} />}
          {!formReadOnly && dokobitModalOpen && (
            <DokobitModal url={dokobitFrameUrl} onClose={onDokobitFrameClose} onLoad={onDokobitFrameReload} />
          )}
        </div>
      )}
    </>
  );
};

export default LeasingRequest;
