import React, { useEffect, useState } from "react";
import { getPartnerLogo } from "services/formService";

const PartnerLogo = ({ partnerToken }) => {
  const [logo, setLogo] = useState();

  useEffect(() => {
    if (partnerToken) {
      getPartnerLogo(partnerToken).then((response) => {
        if (response.ok) {
          setLogo(response.payload);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {logo ? (
        <div className="partner-logo">
          <img className="logo" src={logo} alt="Partner Logo" />
        </div>
      ) : (
        <>&nbsp;</>
      )}
    </>
  );
};

export default PartnerLogo;
